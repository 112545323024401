var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { flattenDeep, uniq, values } from 'lodash-es';
import { Protocol } from 'codesandbox-api';
import fs from 'fs';
import gensync from 'gensync';
import * as pathUtils from '@codesandbox/common/lib/utils/path';
import { isUrl } from '@codesandbox/common/lib/utils/is-url';
import _debug from '@codesandbox/common/lib/utils/debug';
import { getGlobal } from '@codesandbox/common/lib/utils/global';
import { endMeasure, now } from '@codesandbox/common/lib/utils/metrics';
import DependencyNotFoundError from 'sandbox-hooks/errors/dependency-not-found-error';
import ModuleNotFoundError from 'sandbox-hooks/errors/module-not-found-error';
import { resolveAsync, resolveSync } from './resolver/resolver';
import { generateBenchmarkInterface } from './utils/benchmark';
import { TranspiledModule, } from './transpiled-module/transpiled-module';
import fetchModule, { setCombinedMetas, combinedMetas, } from './npm/dynamic/fetch-npm-module';
import coreLibraries from './npm/get-core-libraries';
import dependenciesToQuery from './npm/dependencies-to-query';
import { getAliasVersion, getDependencyName, } from './utils/get-dependency-name';
import { ignoreNextCache, deleteAPICache, clearIndexedDBCache, } from './cache';
import { splitQueryFromPath } from './transpiled-module/utils/query-path';
import { prependToContributedProtocols, } from './npm/dynamic/fetch-protocols';
import { FileFetcher } from './npm/dynamic/fetch-protocols/file';
import { DEFAULT_EXTENSIONS } from './utils/extensions';
import { fetchWithRetries } from './npm/dynamic/fetch-protocols/utils';
import { getESModuleUrl } from './utils/esmodule-url';
const NODE_LIBS = ['dgram', 'net', 'tls', 'fs', 'module', 'child_process'];
// For these dependencies we don't want to follow along with the `browser` field
const SKIPPED_BROWSER_FIELD_DEPENDENCIES = [
    'babel-core',
    '@babel/core',
].reduce((result, next) => (Object.assign(Object.assign({}, result), { [`/node_modules/${next}/package.json`]: true })), {});
const SHIMMED_MODULE = {
    path: pathUtils.join('/node_modules', 'empty', 'index.js'),
    code: `// empty`,
    requires: [],
};
const getShimmedModuleFromPath = (currentPath, path) => (Object.assign(Object.assign({}, SHIMMED_MODULE), { path: pathUtils.join(pathUtils.dirname(currentPath), path) }));
const debug = _debug('cs:compiler:manager');
function triggerFileWatch(path, type) {
    try {
        // @ts-ignore
        fs.getFSModule().fileWatcher.triggerWatch(path, type);
    }
    catch (e) {
        /* ignore */
    }
}
function fetchRemoteModule(url) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const r = yield fetchWithRetries(url);
            if (!r.ok) {
                throw new Error(`Fetching ESModule return error status ${r.status}`);
            }
            const content = yield r.text();
            return {
                url: r.url,
                content,
            };
        }
        catch (err) {
            console.error(err);
            throw new ModuleNotFoundError(url, true);
        }
    });
}
export default class Manager {
    constructor(id, preset, modules, options, cb) {
        this.envVariables = {};
        this.manifest = {
            contents: {},
            dependencies: [],
            dependencyDependencies: {},
            dependencyAliases: {},
        };
        this.hmrStatus = 'idle';
        this.isReloading = false;
        this.bfsWrapper = {
            getTranspiledModules: () => this.transpiledModules,
            addModule: (module) => {
                this.addModule(module);
            },
            removeModule: (module) => {
                this.removeModule(module);
            },
            moveModule: (module, newPath) => {
                this.moveModule(module, newPath);
            },
            updateModule: (module) => {
                this.updateModule(module);
            },
        };
        this._isFileSync = (p) => {
            if (this.stage === 'transpilation') {
                // In transpilation phase we can afford to download the file if not found,
                // because we're async. That's why we also include the meta here.
                return Boolean(this.transpiledModules[p] || combinedMetas[p]);
            }
            return Boolean(this.transpiledModules[p]);
        };
        this._isFileAsync = (p) => {
            const exists = this._isFileSync(p);
            if (exists) {
                return Promise.resolve(true);
            }
            if (this.fileResolver) {
                return this.fileResolver.isFile(p);
            }
            return Promise.resolve(false);
        };
        this.isFile = gensync({
            sync: this._isFileSync,
            async: this._isFileAsync,
        });
        this._readFileSync = (p) => {
            if (!this.transpiledModules[p]) {
                const err = new Error('Could not find ' + p);
                // @ts-ignore
                err.code = 'ENOENT';
                throw err;
            }
            return this.transpiledModules[p].module.code;
        };
        this._readFileAsync = (p) => {
            try {
                const content = this._readFileSync(p);
                return Promise.resolve(content);
            }
            catch (err) {
                if (this.fileResolver) {
                    return this.fileResolver
                        .readFile(p)
                        .then(code => {
                        this.addModule({ path: p, code });
                        return code;
                    })
                        .catch(() => {
                        throw err;
                    });
                }
                return Promise.reject(err);
            }
        };
        this.readFile = gensync({
            sync: this._readFileSync,
            async: this._readFileAsync,
        });
        this.setStage = (stage) => {
            this.stage = stage;
        };
        this.resolveTranspiledModuleAsync = (path, currentTModule, ignoredExtensions) => __awaiter(this, void 0, void 0, function* () {
            const tModule = currentTModule || this.getTranspiledModule(this.modules['/package.json']); // Get arbitrary file from root
            try {
                const transpiledModule = yield this.resolveTranspiledModule(path, tModule.module.path, ignoredExtensions);
                return transpiledModule;
            }
            catch (e) {
                if (e.type === 'module-not-found' && e.isDependency) {
                    const { queryPath } = splitQueryFromPath(path);
                    return this.downloadDependency(e.path, tModule, queryPath, ignoredExtensions);
                }
                throw e;
            }
        });
        this.setHmrStatus = (status) => {
            this.hmrStatusChangeListeners.forEach(v => {
                v(status);
            });
            this.hmrStatus = status;
        };
        this.addStatusHandler = (cb) => {
            this.hmrStatusChangeListeners.add(cb);
        };
        this.removeStatusHandler = (cb) => {
            this.hmrStatusChangeListeners.delete(cb);
        };
        this.id = id;
        this.preset = preset;
        this.transpiledModules = {};
        this.cachedPaths = {};
        this.transpileJobs = {};
        this.webpackHMR = false;
        this.hardReload = false;
        this.hmrStatus = 'idle';
        this.hmrStatusChangeListeners = new Set();
        this.isFirstLoad = true;
        this.transpiledModulesByHash = {};
        this.configurations = {};
        this.stage = 'transpilation';
        this.version = options.versionIdentifier;
        this.esmodules = new Map();
        this.resolverCache = new Map();
        /**
         * Contribute the file fetcher, which needs the manager to resolve the files
         */
        prependToContributedProtocols([
            {
                condition: (name, version) => version.startsWith('file:'),
                protocol: new FileFetcher(this),
            },
        ]);
        this.modules = modules;
        Object.keys(modules).forEach(k => this.addModule(modules[k]));
        getGlobal().manager = this;
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(this);
            // Initialize benchmark logic
            getGlobal().Benchmark = generateBenchmarkInterface(this);
        }
        BrowserFS.configure({
            fs: 'CodeSandboxFS',
            options: {
                manager: this.bfsWrapper,
            },
        }, () => {
            if (cb) {
                cb();
            }
        });
        if (options.hasFileResolver) {
            this.setupFileResolver();
        }
    }
    reload() {
        this.isReloading = true;
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    }
    prependNpmProtocolDefinition(protocol) {
        prependToContributedProtocols([protocol]);
    }
    // Call this whenever the file structure or modules change, so before each compilation...
    resetResolverCache() {
        this.cachedPaths = {};
        this.resolverCache = new Map();
    }
    evaluate(path, baseTModule) {
        return __awaiter(this, void 0, void 0, function* () {
            const tModule = yield this.resolveTranspiledModuleAsync(path, baseTModule, this.preset.ignoredExtensions);
            yield tModule.transpile(this);
            return tModule.evaluate(this);
        });
    }
    setupFileResolver() {
        const protocol = new Protocol('file-resolver', () => true, window.parent);
        this.fileResolver = {
            protocol,
            isFile: (path) => protocol.sendMessage({ m: 'isFile', p: path }),
            readFile: (path) => protocol.sendMessage({ m: 'readFile', p: path }),
        };
    }
    resetAllModules() {
        this.getTranspiledModules().forEach(t => {
            t.resetTranspilation();
            t.resetCompilation();
        });
    }
    setManifest(manifest) {
        this.manifest = manifest || {
            contents: {},
            dependencies: [],
            dependencyDependencies: {},
            dependencyAliases: {},
        };
        Object.keys(this.manifest.contents).forEach(path => {
            const module = {
                path,
                code: this.manifest.contents[path].content,
            };
            if (SKIPPED_BROWSER_FIELD_DEPENDENCIES[path]) {
                const pJsonCode = JSON.parse(this.manifest.contents[path].content);
                // eslint-disable-next-line
                delete pJsonCode.browser;
                module.code = JSON.stringify(pJsonCode, null, 2);
            }
            this.addModule(module);
        });
        debug(`Loaded manifest.`);
    }
    evaluateModule(module, { force = false, globals } = {}) {
        // Do a hard reload
        if (this.hardReload && !this.isFirstLoad) {
            this.reload();
            return {};
        }
        // Page is reloading, skip further evaluation
        if (this.isReloading) {
            return {};
        }
        // Evaluate the *changed* HMR modules first
        this.getTranspiledModules()
            .filter(t => t.hmrConfig && t.hmrConfig.isDirty())
            .forEach(t => t.evaluate(this));
        const transpiledModule = this.getTranspiledModule(module);
        if (force && transpiledModule.compilation) {
            transpiledModule.compilation = null;
        }
        try {
            const exports = this.evaluateTranspiledModule(transpiledModule, undefined, { force, globals });
            if (this.webpackHMR) {
                // Check if any module has been invalidated, because in that case we need to
                // restart evaluation.
                const invalidatedModules = this.getTranspiledModules().filter(t => {
                    var _a;
                    if ((_a = t.hmrConfig) === null || _a === void 0 ? void 0 : _a.invalidated) {
                        t.compilation = null;
                        t.hmrConfig = null;
                        return true;
                    }
                    return false;
                });
                if (invalidatedModules.length > 0) {
                    return this.evaluateModule(module, { force, globals });
                }
            }
            this.setHmrStatus('idle');
            return exports;
        }
        finally {
            // Run post evaluate
            this.getTranspiledModules().forEach(t => t.postEvaluate(this));
        }
    }
    evaluateTranspiledModule(transpiledModule, initiator, { force = false, globals } = {}) {
        if (force && transpiledModule.compilation) {
            transpiledModule.compilation = null;
        }
        return transpiledModule.evaluate(this, { force, globals }, initiator);
    }
    addModule(module) {
        this.transpiledModules[module.path] = this.transpiledModules[module.path] || { module, tModules: {} };
        triggerFileWatch(module.path, 'rename');
    }
    addTranspiledModule(module, query = '') {
        if (!this.transpiledModules[module.path]) {
            this.addModule(module);
        }
        this.transpiledModules[module.path].module = module;
        const transpiledModule = new TranspiledModule(module, query);
        this.transpiledModules[module.path].tModules[query] = transpiledModule;
        this.transpiledModulesByHash[transpiledModule.hash] = transpiledModule;
        return transpiledModule;
    }
    getTranspiledModuleByHash(hash) {
        return this.transpiledModulesByHash[hash];
    }
    /**
     * Get Transpiled Module from the registry, if there is no transpiled module
     * in the registry it will create a new one.
     *
     * @param {Module} module
     * @param {string} query A webpack like syntax (!url-loader)
     */
    getTranspiledModule(module, query = '') {
        const moduleObject = this.transpiledModules[module.path];
        if (!moduleObject) {
            this.addModule(module);
        }
        let transpiledModule = this.transpiledModules[module.path].tModules[query];
        if (!transpiledModule) {
            transpiledModule = this.addTranspiledModule(module, query);
        }
        return transpiledModule;
    }
    /**
     * One module can have multiple transpiled modules, because modules can be
     * required in different ways. For example, require(`babel-loader!./Test.vue`) isn't
     * the same as require(`./Test.vue`).
     *
     * This will return all transpiled modules, with different configurations associated one module.
     * @param {*} module
     */
    getTranspiledModulesByModule(module) {
        return this.transpiledModules[module.path]
            ? values(this.transpiledModules[module.path].tModules)
            : [];
    }
    getTranspiledModules() {
        return values(this.transpiledModulesByHash);
    }
    removeTranspiledModule(tModule) {
        delete this.transpiledModulesByHash[tModule.hash];
        delete this.transpiledModules[tModule.module.path].tModules[tModule.query];
    }
    removeModule(module) {
        // File structure changed, reset resolver cache
        this.resetResolverCache();
        const existingModule = this.transpiledModules[module.path];
        values(existingModule.tModules).forEach(m => {
            m.dispose(this);
            this.removeTranspiledModule(m);
        });
        delete this.transpiledModules[module.path];
        triggerFileWatch(module.path, 'rename');
    }
    moveModule(module, newPath) {
        this.removeModule(module);
        this.addModule(Object.assign(Object.assign({}, module), { path: newPath }));
    }
    setEnvironmentVariables() {
        if (this.transpiledModules['/.env'] && this.preset.hasDotEnv) {
            const envCode = this.transpiledModules['/.env'].module.code;
            this.envVariables = {};
            try {
                envCode.split('\n').forEach(envLine => {
                    const [name, ...val] = envLine.split('=');
                    this.envVariables[name] = val.join('=').replace(/^('|")|('|")$/g, '');
                });
            }
            catch (e) {
                console.error(e);
            }
        }
    }
    /**
     * Will transpile this module and all eventual children (requires) that go with it
     * @param {*} entry
     */
    transpileModules(entry, isTestFile = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setHmrStatus('check');
            this.setEnvironmentVariables();
            const transpiledModule = this.getTranspiledModule(entry);
            transpiledModule.setIsEntry(true);
            transpiledModule.setIsTestFile(isTestFile);
            const result = yield transpiledModule.transpile(this);
            this.getTranspiledModules().forEach(t => t.postTranspile(this));
            return result;
        });
    }
    verifyTreeTranspiled() {
        return Promise.all(this.getTranspiledModules()
            .filter(tModule => tModule.shouldTranspile())
            .map(tModule => tModule.transpile(this)));
    }
    clearCompiledCache() {
        this.getTranspiledModules().map(tModule => tModule.resetCompilation());
    }
    clearTranspilationCache() {
        this.getTranspiledModules().map(tModule => tModule.resetTranspilation());
    }
    getModules() {
        return values(this.transpiledModules).map(t => t.module);
    }
    /**
     * The packager returns a list of dependencies that require a different path
     * of their subdependencies.
     *
     * An example:
     * if react requires lodash v3, and react-dom requires lodash v4. We add them
     * both to the bundle, and rewrite paths for lodash v3 to `lodash/3.0.0/`. Then
     * we specify that when react resolves `lodash` it should resolve `lodash/3.0.0`.
     *
     * @param {string} path
     * @param {string} currentPath
     * @returns
     * @memberof Manager
     */
    getAliasedDependencyPath(path, currentPath) {
        const isDependency = /^(\w|@\w|@-)/.test(path);
        if (!isDependency) {
            return path;
        }
        const isCurrentPathDependency = currentPath.startsWith('/node_modules');
        if (!isCurrentPathDependency) {
            return path;
        }
        const dependencyName = getDependencyName(path);
        const currentDependencyName = getDependencyName(currentPath.replace('/node_modules/', ''));
        // There's a case where an aliased dependency requests itself. Eg. @babel/runtime/7.13.1 has an import
        // of @babel/runtime. We know that this dependency needs to be @babel/runtime/7.13.1, so we do an explicit
        // check for this here.
        if (currentDependencyName.startsWith(dependencyName + '/')) {
            // Okay, we now know for sure that the current dependency is aliased, and the imported dependency is the same
            const aliasedVersion = getAliasVersion(currentPath.replace('/node_modules/', ''));
            if (aliasedVersion) {
                return path.replace(dependencyName, dependencyName + '/' + aliasedVersion);
            }
        }
        if (currentDependencyName &&
            dependencyName &&
            this.manifest.dependencyAliases[currentDependencyName] &&
            this.manifest.dependencyAliases[currentDependencyName][dependencyName]) {
            const aliasedDependencyName = this.manifest.dependencyAliases[currentDependencyName][dependencyName];
            return path.replace(dependencyName, aliasedDependencyName);
        }
        return path;
    }
    /**
     * Set the manager to use Webpack HMR, this changes how modules are hot reloaded
     * and how the manager cleans up the website.
     *
     * @memberof Manager
     */
    enableWebpackHMR() {
        this.webpackHMR = true;
    }
    getPresetAliasedPath(path) {
        return this.preset
            .getAliasedPath(path)
            .replace(/.*\{\{sandboxRoot\}\}/, '');
    }
    getModuleDirectories() {
        var _a, _b;
        if (this.moduleDirectoriesCache) {
            return this.moduleDirectoriesCache;
        }
        const baseTSCompilerConfig = [
            this.configurations.typescript,
            this.configurations.jsconfig,
        ].find(config => config && config.generated !== true);
        let baseUrl = (_b = (_a = baseTSCompilerConfig === null || baseTSCompilerConfig === void 0 ? void 0 : baseTSCompilerConfig.parsed) === null || _a === void 0 ? void 0 : _a.compilerOptions) === null || _b === void 0 ? void 0 : _b.baseUrl;
        // TODO: we need to extract our resolver to a plugin system and use the TypeScript resolver
        // if we see a tsconfig. A `.` doesn't work and messes up resolving.
        if (baseUrl === '.') {
            baseUrl = undefined;
        }
        this.moduleDirectoriesCache = [
            'node_modules',
            baseUrl,
            this.envVariables.NODE_PATH,
        ].filter(Boolean);
        return this.moduleDirectoriesCache;
    }
    downloadESModule(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const mod = this.esmodules.get(url) || fetchRemoteModule(url);
            this.esmodules.set(url, mod);
            return mod;
        });
    }
    // ALWAYS KEEP THIS METHOD IN SYNC WITH SYNC VERSION
    resolveModuleAsync(opts) {
        return __awaiter(this, void 0, void 0, function* () {
            const { path, query = '', defaultExtensions = DEFAULT_EXTENSIONS } = opts;
            let parentPath = opts.parentPath || '/index.js';
            const esmoduleUrl = getESModuleUrl(parentPath, path);
            // Handle ESModule import
            if (esmoduleUrl) {
                if (!this.preset.experimentalEsmSupport) {
                    throw new Error('ESModules url imports are only supported in the experimental ESModule preset.');
                }
                const fullUrl = `${esmoduleUrl}?${query}`;
                const cachedModule = this.transpiledModules[fullUrl];
                if (cachedModule) {
                    return cachedModule.module;
                }
                const downloadResult = yield this.downloadESModule(fullUrl);
                this.addModule({
                    path: fullUrl,
                    url: downloadResult.url,
                    code: downloadResult.content || '',
                    downloaded: true,
                });
                return this.transpiledModules[fullUrl].module;
            }
            // This handles the imports of node_modules from remote ESModules
            // In the future we should probably resolve these from a pkg.json the ESModule provides...
            if (isUrl(parentPath) && !esmoduleUrl) {
                // eslint-disable-next-line no-param-reassign
                parentPath = '/package.json';
            }
            const dirredPath = pathUtils.dirname(parentPath);
            if (this.cachedPaths[dirredPath] === undefined) {
                this.cachedPaths[dirredPath] = {};
            }
            const cachedPath = this.cachedPaths[dirredPath][path];
            let resolvedPath;
            if (cachedPath && this.transpiledModules[cachedPath]) {
                resolvedPath = cachedPath;
            }
            else {
                const measureKey = `resolve-async:${path}::${parentPath}::${query}`;
                const measureStartTime = now();
                const presetAliasedPath = this.getPresetAliasedPath(path);
                const aliasedPath = this.getAliasedDependencyPath(presetAliasedPath, parentPath);
                const shimmedPath = coreLibraries[aliasedPath] || aliasedPath;
                if (NODE_LIBS.indexOf(shimmedPath) > -1) {
                    this.cachedPaths[dirredPath][path] = shimmedPath;
                    return getShimmedModuleFromPath(parentPath, path);
                }
                try {
                    resolvedPath = yield resolveAsync(shimmedPath, {
                        filename: parentPath,
                        extensions: defaultExtensions.map(ext => '.' + ext),
                        isFile: this.isFile,
                        readFile: this.readFile,
                        moduleDirectories: this.getModuleDirectories(),
                        resolverCache: this.resolverCache,
                    });
                    endMeasure(measureKey, { silent: true, lastTime: measureStartTime });
                    this.cachedPaths[dirredPath][path] = resolvedPath;
                    if (resolvedPath === '//empty.js') {
                        return getShimmedModuleFromPath(parentPath, path);
                    }
                    if (!this.transpiledModules[resolvedPath]) {
                        try {
                            const remoteFileContent = yield this.readFile.async(resolvedPath);
                            this.addModule({
                                path: resolvedPath,
                                code: remoteFileContent || '',
                            });
                        }
                        catch (err) {
                            throw new Error(`Could not find '${resolvedPath}' in local files.`);
                        }
                    }
                }
                catch (err) {
                    if (this.cachedPaths[dirredPath] &&
                        this.cachedPaths[dirredPath][path]) {
                        delete this.cachedPaths[dirredPath][path];
                    }
                    let connectedPath = shimmedPath;
                    if (connectedPath.indexOf('/node_modules') !== 0) {
                        connectedPath = /^(\w|@\w|@-)/.test(shimmedPath)
                            ? pathUtils.join('/node_modules', shimmedPath)
                            : pathUtils.join(pathUtils.dirname(parentPath), shimmedPath);
                    }
                    const isDependency = connectedPath.includes('/node_modules/');
                    connectedPath = connectedPath.replace('/node_modules/', '');
                    if (!isDependency) {
                        throw new ModuleNotFoundError(shimmedPath, false, parentPath);
                    }
                    const dependencyName = getDependencyName(connectedPath);
                    // TODO: fix the stack hack
                    if (dependencyName &&
                        (this.manifest.dependencies.find(d => d.name === dependencyName) ||
                            this.manifest.dependencyDependencies[dependencyName] ||
                            this.manifest.contents[`/node_modules/${dependencyName}/package.json`])) {
                        throw new ModuleNotFoundError(connectedPath, true, parentPath);
                    }
                    else {
                        throw new DependencyNotFoundError(connectedPath, parentPath);
                    }
                }
            }
            if (resolvedPath === '//empty.js') {
                return getShimmedModuleFromPath(parentPath, path);
            }
            return this.transpiledModules[resolvedPath].module;
        });
    }
    // ALWAYS KEEP THIS METHOD IN SYNC WITH ASYNC VERSION
    resolveModule({ path, parentPath = '/', query = '', defaultExtensions = DEFAULT_EXTENSIONS, }) {
        const esmoduleUrl = getESModuleUrl(parentPath, path);
        // Handle ESModule import
        if (esmoduleUrl) {
            if (!this.preset.experimentalEsmSupport) {
                throw new Error('ESModules url imports are only supported in the experimental ESModule preset.');
            }
            const fullUrl = `${esmoduleUrl}?${query}`;
            const cachedModule = this.transpiledModules[fullUrl];
            if (cachedModule) {
                return cachedModule.module;
            }
            throw new Error(`Cannot download ESModule dependencies synchronously: ${fullUrl}`);
        }
        // This handles the imports of node_modules from remote ESModules
        // In the future we should probably resolve these from a pkg.json the ESModule provides...
        if (isUrl(parentPath) && !esmoduleUrl) {
            // eslint-disable-next-line no-param-reassign
            parentPath = '/package.json';
        }
        const dirredPath = pathUtils.dirname(parentPath);
        if (this.cachedPaths[dirredPath] === undefined) {
            this.cachedPaths[dirredPath] = {};
        }
        const cachedPath = this.cachedPaths[dirredPath][path];
        let resolvedPath;
        if (cachedPath && this.transpiledModules[cachedPath]) {
            resolvedPath = cachedPath;
        }
        else {
            const measureKey = `resolve-sync:${path}:${parentPath}`;
            const measureStartTime = now();
            const presetAliasedPath = this.getPresetAliasedPath(path);
            const aliasedPath = this.getAliasedDependencyPath(presetAliasedPath, parentPath);
            const shimmedPath = coreLibraries[aliasedPath] || aliasedPath;
            if (NODE_LIBS.indexOf(shimmedPath) > -1) {
                this.cachedPaths[dirredPath][path] = shimmedPath;
                return getShimmedModuleFromPath(parentPath, path);
            }
            try {
                resolvedPath = resolveSync(shimmedPath, {
                    filename: parentPath,
                    extensions: defaultExtensions.map(ext => '.' + ext),
                    isFile: this.isFile,
                    readFile: this.readFile,
                    moduleDirectories: this.getModuleDirectories(),
                    resolverCache: this.resolverCache,
                });
                endMeasure(measureKey, { silent: true, lastTime: measureStartTime });
                this.cachedPaths[dirredPath][path] = resolvedPath;
                if (resolvedPath === '//empty.js') {
                    return getShimmedModuleFromPath(parentPath, path);
                }
                if (!this.transpiledModules[resolvedPath]) {
                    throw new Error(`Could not find '${resolvedPath}' in local files.`);
                }
            }
            catch (e) {
                // MAKE SURE TO SYNC THIS WITH ASYNC VERSION
                if (this.cachedPaths[dirredPath] &&
                    this.cachedPaths[dirredPath][path]) {
                    delete this.cachedPaths[dirredPath][path];
                }
                let connectedPath = shimmedPath;
                if (connectedPath.indexOf('/node_modules') !== 0) {
                    connectedPath = /^(\w|@\w|@-)/.test(shimmedPath)
                        ? pathUtils.join('/node_modules', shimmedPath)
                        : pathUtils.join(pathUtils.dirname(parentPath), shimmedPath);
                }
                const isDependency = connectedPath.includes('/node_modules/');
                connectedPath = connectedPath.replace('/node_modules/', '');
                if (!isDependency) {
                    throw new ModuleNotFoundError(shimmedPath, false, parentPath);
                }
                const dependencyName = getDependencyName(connectedPath);
                // TODO: fix the stack hack
                if (dependencyName &&
                    (this.manifest.dependencies.find(d => d.name === dependencyName) ||
                        this.manifest.dependencyDependencies[dependencyName] ||
                        this.manifest.contents[`/node_modules/${dependencyName}/package.json`])) {
                    throw new ModuleNotFoundError(connectedPath, true, parentPath);
                }
                else {
                    throw new DependencyNotFoundError(connectedPath, parentPath);
                }
            }
        }
        if (resolvedPath === '//empty.js') {
            return getShimmedModuleFromPath(parentPath, path);
        }
        return this.transpiledModules[resolvedPath].module;
    }
    downloadDependency(path, currentTModule, query = '', ignoredExtensions = this.preset.ignoredExtensions) {
        return fetchModule(path, currentTModule, this, ignoredExtensions).then(module => {
            this.resetResolverCache();
            return this.getTranspiledModule(module, query);
        });
    }
    updateModule(m) {
        this.transpiledModules[m.path].module = m;
        triggerFileWatch(m.path, 'change');
        return this.getTranspiledModulesByModule(m).map(tModule => {
            tModule.update(m);
            return tModule;
        });
    }
    /**
     * Resolve the transpiled module from the path, note that the path can actually
     * include loaders. That's why we're focussing on first extracting this query
     * @param {*} path
     * @param {*} currentPath
     */
    resolveTranspiledModule(path, currentPath, ignoredExtensions) {
        return __awaiter(this, void 0, void 0, function* () {
            if (path.startsWith('webpack:')) {
                throw new Error('Cannot resolve webpack path');
            }
            const { queryPath, modulePath } = splitQueryFromPath(path);
            const resolvedModule = yield this.resolveModuleAsync({
                path: modulePath,
                parentPath: currentPath,
                query: queryPath,
                defaultExtensions: ignoredExtensions || this.preset.ignoredExtensions,
            });
            return this.getTranspiledModule(resolvedModule, queryPath);
        });
    }
    /**
     * Resolve the transpiled module from the path, note that the path can actually
     * include loaders. That's why we're focussing on first extracting this query
     * @param {*} path
     * @param {*} currentPath
     */
    resolveTranspiledModuleSync(path, currentPath, ignoredExtensions) {
        if (path.startsWith('webpack:')) {
            throw new Error('Cannot resolve webpack path');
        }
        const { queryPath, modulePath } = splitQueryFromPath(path);
        const resolvedModule = this.resolveModule({
            path: modulePath,
            parentPath: currentPath,
            query: queryPath,
            defaultExtensions: ignoredExtensions || this.preset.ignoredExtensions,
        });
        return this.getTranspiledModule(resolvedModule, queryPath);
    }
    resolveTranspiledModulesInDirectory(path, currentPath) {
        const { queryPath, modulePath } = splitQueryFromPath(path);
        const joinedPath = pathUtils.join(pathUtils.dirname(currentPath), modulePath);
        return Object.keys(this.transpiledModules)
            .filter(p => p.startsWith(joinedPath))
            .map(m => this.getTranspiledModule(this.transpiledModules[m].module, queryPath));
    }
    updateConfigurations(configurations) {
        const configsUpdated = this.configurations
            ? JSON.stringify(configurations) !== JSON.stringify(this.configurations)
            : false;
        if (configsUpdated) {
            this.resetAllModules();
        }
        this.configurations = configurations;
    }
    /**
     * Find all changed, added and deleted modules. Update trees and
     * delete caches accordingly
     */
    updateData(modules) {
        return __awaiter(this, void 0, void 0, function* () {
            this.transpileJobs = {};
            this.hardReload = false;
            this.modules = modules;
            const addedModules = [];
            const updatedModules = [];
            // File structure likely changed, reset resolver cache
            this.resetResolverCache();
            Object.keys(modules).forEach(k => {
                const module = modules[k];
                const mirrorModule = this.transpiledModules[k];
                if (!mirrorModule) {
                    addedModules.push(module);
                }
                else if (mirrorModule.module.code !== module.code) {
                    updatedModules.push(module);
                }
            });
            this.getModules().forEach(m => {
                if (!m.path.startsWith('/node_modules') &&
                    m.path !== '/var/task/node_modules/browser-resolve/empty.js' &&
                    !isUrl(m.path) &&
                    !modules[m.path] &&
                    !m.parent // not an emitted module
                ) {
                    this.removeModule(m);
                }
            });
            addedModules.forEach(m => {
                this.addTranspiledModule(m);
            });
            const modulesToUpdate = uniq([
                ...addedModules,
                ...updatedModules,
            ]);
            // We eagerly transpile changed files,
            // this way we don't have to traverse the whole
            // dependency graph each time a file changes
            const tModulesToUpdate = modulesToUpdate.map(m => this.updateModule(m));
            if (tModulesToUpdate.length > 0 && this.configurations.sandbox) {
                this.hardReload = this.configurations.sandbox.parsed.hardReloadOnChange;
            }
            const modulesWithErrors = this.getTranspiledModules().filter(t => {
                if (t.hasMissingDependencies) {
                    t.resetTranspilation();
                }
                return t.errors.length > 0 || t.hasMissingDependencies;
            });
            const flattenedTModulesToUpdate = flattenDeep([
                tModulesToUpdate,
                modulesWithErrors,
            ]);
            const allModulesToUpdate = uniq(flattenedTModulesToUpdate);
            const transpiledModulesToUpdate = allModulesToUpdate.filter(m => !m.isTestFile);
            // Reset test files, but don't transpile. We want to do that in the test runner
            // so we can catch any errors
            allModulesToUpdate
                .filter(m => m.isTestFile)
                .forEach(m => {
                m.resetTranspilation();
            });
            debug(`Generated update diff, updating ${transpiledModulesToUpdate.length} modules.`, transpiledModulesToUpdate);
            const transpilationResults = yield Promise.all(transpiledModulesToUpdate
                .map(tModule => {
                if (tModule.shouldTranspile()) {
                    return tModule.transpile(this);
                }
                return Promise.resolve(false);
            })
                .filter(Boolean));
            return transpilationResults;
        });
    }
    /**
     * Mark that the next evaluation should first have a location.reload() before
     * continuing
     */
    markHardReload() {
        this.setHmrStatus('fail');
        this.hardReload = true;
    }
    serialize({ entryPath, optimizeForSize, } = {
        optimizeForSize: true,
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            const serializedTModules = {};
            yield Promise.all(Object.keys(this.transpiledModules).map(path => Promise.all(Object.keys(this.transpiledModules[path].tModules).map((query) => __awaiter(this, void 0, void 0, function* () {
                const tModule = this.transpiledModules[path].tModules[query];
                if (!this.manifest.contents[tModule.module.path] ||
                    tModule.module.downloaded) {
                    // Only save modules that are not precomputed
                    serializedTModules[tModule.getId()] = yield tModule.serialize(optimizeForSize);
                }
            })))));
            const dependenciesQuery = this.getDependencyQuery();
            const meta = {};
            Object.keys(combinedMetas || {}).forEach(p => {
                const dir = pathUtils.dirname(p.replace('/node_modules', ''));
                meta[dir] = meta[dir] || [];
                meta[dir].push(pathUtils.basename(p));
            });
            return {
                transpiledModules: serializedTModules,
                cachedPaths: this.cachedPaths,
                version: this.version,
                timestamp: new Date().getTime(),
                configurations: this.configurations,
                entry: entryPath,
                meta,
                dependenciesQuery,
            };
        });
    }
    getDependencyQuery() {
        if (!this.manifest || !this.manifest.dependencies) {
            return '';
        }
        const normalizedDependencies = {};
        this.manifest.dependencies.forEach(dep => {
            normalizedDependencies[dep.name] = dep.version;
        });
        return dependenciesToQuery(normalizedDependencies);
    }
    load(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (data) {
                    this.clearCache();
                    const { transpiledModules: serializedTModules, cachedPaths, version: cacheVersion, configurations, dependenciesQuery, meta, } = data;
                    // Only use the cache if the cached version was cached with the same
                    // version of the compiler and dependencies haven't changed
                    if (cacheVersion === this.version &&
                        dependenciesQuery === this.getDependencyQuery()) {
                        const newCombinedMetas = {};
                        Object.keys(meta).forEach(dir => {
                            meta[dir].forEach(file => {
                                newCombinedMetas[`/node_modules` + dir + '/' + file] = true;
                            });
                        });
                        setCombinedMetas(newCombinedMetas);
                        this.cachedPaths = cachedPaths;
                        this.configurations = configurations;
                        const tModules = {};
                        // First create tModules for all the saved modules, so we have references
                        Object.keys(serializedTModules).forEach(id => {
                            const sTModule = serializedTModules[id];
                            const tModule = this.addTranspiledModule(sTModule.module, sTModule.query);
                            tModules[id] = tModule;
                        });
                        yield Promise.all(Object.keys(tModules).map(id => {
                            const tModule = tModules[id];
                            return tModule.load(serializedTModules[id], tModules, this);
                        }));
                        debug(`Loaded cache.`);
                    }
                }
            }
            catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.warn('Problems parsing cache');
                    console.warn(e);
                }
            }
            this.clearCache();
        });
    }
    dispose() {
        if (this.preset) {
            this.preset.getTranspilers().forEach(t => {
                if (t.dispose) {
                    t.dispose();
                }
            });
            if (this.fileResolver) {
                this.fileResolver.protocol.dispose();
            }
        }
    }
    deleteAPICache() {
        ignoreNextCache();
        if (!this.id) {
            return Promise.resolve();
        }
        return deleteAPICache(this.id, this.version);
    }
    clearCache() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.moduleDirectoriesCache = undefined;
                yield clearIndexedDBCache();
            }
            catch (ex) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(ex);
                }
            }
        });
    }
    /**
     * Get information about all transpilers currently registered for this manager
     */
    getTranspilerContext() {
        return __awaiter(this, void 0, void 0, function* () {
            const info = {};
            const data = yield Promise.all(this.preset
                .getTranspilers()
                .map(t => t
                .getTranspilerContext(this)
                .then(context => ({ name: t.name, data: context }))));
            data.forEach(t => {
                info[t.name] = t.data;
            });
            return info;
        });
    }
}
