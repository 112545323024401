var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import minimatch from 'minimatch';
import * as semver from 'semver';
import { downloadDependency } from './fetch-npm-module';
export function getPackageJSON(dep, version) {
    return __awaiter(this, void 0, void 0, function* () {
        const m = yield downloadDependency(dep, version, '/package.json');
        return m.code;
    });
}
function getLatestVersionForSemver(dep, version) {
    return __awaiter(this, void 0, void 0, function* () {
        const p = yield getPackageJSON(dep, version);
        return JSON.parse(p).version;
    });
}
function getAbsoluteVersion(originalDep, depName, depVersion, parsedResolutions) {
    // Try getting it from the resolutions field first, if that doesn't work
    // we try to get the latest version from the semver.
    const applicableResolutions = parsedResolutions[depName];
    if (applicableResolutions) {
        const modulePath = [originalDep, depName].join('/');
        const { range } = applicableResolutions.find(({ globPattern }) => minimatch(modulePath, globPattern)) || {};
        if (range) {
            if (semver.valid(range)) {
                return getLatestVersionForSemver(depName, range);
            }
            return range;
        }
    }
    return getLatestVersionForSemver(depName, depVersion);
}
function getDependencyDependencies(dep, version, parsedResolutions, peerDependencyResult = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const packageJSONCode = yield getPackageJSON(dep, version);
        const packageJSON = JSON.parse(packageJSONCode);
        yield Promise.all(Object.keys(packageJSON.dependencies || {}).map((depName) => __awaiter(this, void 0, void 0, function* () {
            const depVersion = packageJSON.dependencies[depName];
            if (peerDependencyResult[depName]) {
                if (peerDependencyResult[depName].parents.indexOf(dep) === -1) {
                    peerDependencyResult[depName].parents.push(dep);
                }
                return;
            }
            const absoluteVersion = yield getAbsoluteVersion(dep, depName, depVersion, parsedResolutions);
            // In case inbetween the peer dependency result has been added already
            if (peerDependencyResult[depName]) {
                if (peerDependencyResult[depName].parents.indexOf(dep) === -1) {
                    peerDependencyResult[depName].parents.push(dep);
                }
            }
            else {
                // eslint-disable-next-line
                peerDependencyResult[depName] = {
                    semver: depVersion,
                    resolved: absoluteVersion,
                    parents: [dep],
                    entries: [],
                };
                yield getDependencyDependencies(depName, depVersion, parsedResolutions, peerDependencyResult);
            }
        })));
        return peerDependencyResult;
    });
}
export function resolveDependencyInfo(dep, version, parsedResolutions) {
    return __awaiter(this, void 0, void 0, function* () {
        const IS_ALIAS = /^npm:/;
        const packageJSONCode = yield getPackageJSON(dep, version);
        const packageJSON = JSON.parse(packageJSONCode);
        const response = {
            contents: {},
            dependency: {
                name: dep,
                version,
            },
            peerDependencies: {},
            dependencyDependencies: {},
            dependencyAliases: {},
        };
        const resolutionsByPackage = {};
        parsedResolutions.forEach(res => {
            resolutionsByPackage[res.name] = resolutionsByPackage[res.name] || [];
            resolutionsByPackage[res.name].push(res);
        });
        response.peerDependencies = packageJSON.peerDependencies || {};
        response.dependencyDependencies = yield getDependencyDependencies(dep, version, resolutionsByPackage);
        response.contents = {
            [`/node_modules/${dep}/package.json`]: {
                content: packageJSONCode,
            },
        };
        yield Promise.all(Object.keys(response.dependencyDependencies).map((packageName) => __awaiter(this, void 0, void 0, function* () {
            let packageVersion = response.dependencyDependencies[packageName].resolved;
            // If the package is a remote module or is an alias we use the semver as the version for fetching
            const pkgSemver = response.dependencyDependencies[packageName].semver;
            if (pkgSemver && (pkgSemver.match(IS_ALIAS) || pkgSemver.includes('/'))) {
                packageVersion = pkgSemver;
            }
            const pkgJson = yield getPackageJSON(packageName, packageVersion);
            response.contents[`/node_modules/${packageName}/package.json`] = {
                content: pkgJson,
            };
        })));
        return response;
    });
}
