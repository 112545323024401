var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { satisfies, valid } from 'semver';
import { fetchWithRetries } from './utils';
import { TarStore } from './utils/tar-store';
const NPM_REGISTRY_ACCEPT_HEADER = 'application/vnd.npm.install-v1+json; q=1.0, application/json; q=0.8, */*';
function join(a, b) {
    if (a.endsWith('/')) {
        return a + b;
    }
    return a + '/' + b;
}
export class NpmRegistryFetcher {
    constructor(registryLocation, config) {
        this.registryLocation = registryLocation;
        this.tarStore = new TarStore();
        this.packageMetadata = new Map();
        this.condition = (name, version) => {
            if (this.scopeWhitelist) {
                return this.scopeWhitelist.some(scope => name.startsWith(scope));
            }
            return true;
        };
        this.proxyUrl = config.proxyUrl;
        this.scopeWhitelist = config.scopeWhitelist;
        this.authToken = config.authToken;
        this.provideTarballUrl = config.provideTarballUrl;
    }
    getProxiedUrl(url) {
        if (this.proxyUrl) {
            return this.proxyUrl + '?registryurl=' + url;
        }
        return url;
    }
    getTarballUrl(name, version, tarballUrl) {
        if (this.provideTarballUrl) {
            return this.provideTarballUrl(name, version, tarballUrl);
        }
        return this.getProxiedUrl(tarballUrl);
    }
    getPackageUrl(name) {
        const encodedName = name.replace('/', '%2f');
        return this.getProxiedUrl(join(this.registryLocation, encodedName));
    }
    getRequestInit() {
        const headers = new Headers();
        headers.append('Accept', NPM_REGISTRY_ACCEPT_HEADER);
        headers.append('Content-Type', 'application/json');
        if (this.authToken) {
            headers.append('Authorization', `Bearer ${this.authToken}`);
        }
        return {
            method: 'get',
            headers,
            mode: 'cors',
        };
    }
    fetchRegistry(url) {
        return fetchWithRetries(url, 3, this.getRequestInit())
            .then(x => x.json())
            .catch((e) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            let errorMessage = 'Make sure the right auth token and URL are set';
            if (e.responseObject) {
                const res = yield e.responseObject.json();
                if (res.error) {
                    errorMessage = res.error;
                }
                else if ((_a = res.errors) === null || _a === void 0 ? void 0 : _a.detail) {
                    errorMessage = res.errors.detail[0];
                }
            }
            return Promise.reject(new Error(`Could not fetch from registry. ${errorMessage}.`));
        }));
    }
    getPackageMetadata(name) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.packageMetadata.has(name)) {
                this.packageMetadata.set(name, this.fetchRegistry(this.getPackageUrl(name)));
            }
            return this.packageMetadata.get(name);
        });
    }
    getAbsoluteVersion(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            if (valid(version)) {
                return version;
            }
            const metadata = yield this.getPackageMetadata(name);
            if (metadata['dist-tags'] && metadata['dist-tags'][version]) {
                return metadata['dist-tags'][version];
            }
            const versions = Object.keys(metadata.versions).reverse();
            const foundVersion = versions.find(absoluteVersion => satisfies(absoluteVersion, version));
            if (!foundVersion) {
                throw new Error(`Can't find version that satisfies ${name}@${version}`);
            }
            return foundVersion;
        });
    }
    getVersionInfo(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const absoluteVersion = yield this.getAbsoluteVersion(name, version);
            const metadata = yield this.getPackageMetadata(name);
            const versionInfo = metadata.versions[absoluteVersion];
            if (!versionInfo) {
                throw new Error(`Version '${version}' is not available on the registry for '${name}'`);
            }
            return versionInfo;
        });
    }
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const versionInfo = yield this.getVersionInfo(name, version);
            const tarball = this.getTarballUrl(name, versionInfo.version, versionInfo.dist.tarball);
            return this.tarStore.file(name, tarball, path, this.getRequestInit());
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const versionInfo = yield this.getVersionInfo(name, version);
            const tarball = this.getTarballUrl(name, versionInfo.version, versionInfo.dist.tarball);
            return this.tarStore.meta(name, tarball, this.getRequestInit());
        });
    }
}
