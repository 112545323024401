var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { orderBy } from 'lodash-es';
import querystring from 'querystring';
import { WebpackTranspiler } from '../transpiler/transpilers/webpack';
/**
 * This is essentially where it all comes together. The manager is responsible for
 * doing evaluation and transpilation using the Transpiler and Loader classes.
 *
 * You can build presets with this, for example the create-react-app template
 * could create a new instance of this and register all desired loaders.
 *
 * Note that this manager should never have state besides the transpilers and
 * the loaders, the only responsibility is to activate the right transpilers
 * and loaders.
 */
export class Preset {
    constructor(name, ignoredExtensions, alias, { hasDotEnv, processDependencies, setup, teardown, htmlDisabled, preEvaluate, } = {}) {
        this.experimentalEsmSupport = false;
        this.preTranspilers = [];
        this.postTranspilers = [
        // { transpiler: csbDynamicImportTranspiler },
        ];
        this.setAdditionalAliases = (aliases) => {
            this.alias = Object.assign(Object.assign({}, this.defaultAliases), aliases);
            this.aliasedPathCache = {};
        };
        this.reset = () => {
            this.loaders = [];
        };
        this.resetTranspilers = () => {
            this._transpilers.clear();
            this.loaders.length = 0;
        };
        this.aliasedPathCache = {};
        this.parseOptions = (options) => {
            if (options == null) {
                return {};
            }
            try {
                return JSON.parse(options);
            }
            catch (e) {
                return querystring.parse(options);
            }
        };
        this.loaders = [];
        this._transpilers = new Map();
        this.name = name;
        this.hasDotEnv = hasDotEnv || false;
        this.alias = alias || {};
        this.aliasedPathCache = {};
        this.defaultAliases = alias || {};
        this.ignoredExtensions = ignoredExtensions || ['js', 'jsx', 'json', 'mjs'];
        const noop = () => { };
        this.processDependencies = processDependencies || ((deps) => __awaiter(this, void 0, void 0, function* () { return deps; }));
        this.setup = setup || noop;
        this.teardown = teardown || noop;
        this.preEvaluate = preEvaluate || noop;
        this.htmlDisabled = htmlDisabled || false;
        this.postTranspilers.forEach(transpiler => {
            this.addTranspiler(transpiler.transpiler);
        });
    }
    addTranspiler(t) {
        // TODO: Should this overwrite or skip?
        if (this._transpilers.has(t.name))
            return;
        this._transpilers.set(t.name, t);
    }
    getTranspiler(name) {
        return this._transpilers.get(name);
    }
    getTranspilers() {
        return Array.from(this._transpilers.values());
    }
    /**
     * Checks if there is an alias given for the path, if there is it will return
     * the altered path, otherwise it will just return the known path.
     */
    getAliasedPath(path) {
        const aliasCache = this.aliasedPathCache[path];
        if (aliasCache === null) {
            return path;
        }
        if (aliasCache) {
            return aliasCache;
        }
        const aliases = Object.keys(this.alias);
        const exactAliases = aliases.filter(a => a.endsWith('$'));
        const exactFoundAlias = exactAliases.find(a => {
            const alias = a.slice(0, -1);
            if (path === alias) {
                return true;
            }
            return false;
        });
        if (exactFoundAlias) {
            this.aliasedPathCache[path] = this.alias[exactFoundAlias];
            return this.alias[exactFoundAlias];
        }
        const pathParts = path.split('/');
        // Find matching aliases
        const foundAlias = orderBy(aliases, a => -a.split('/').length).find(a => {
            const parts = a.split('/');
            return parts.every((p, i) => pathParts[i] === p);
        });
        if (foundAlias) {
            const replacedPath = path.replace(foundAlias, this.alias[foundAlias]);
            this.aliasedPathCache[path] = replacedPath;
            // if an alias is found we will replace the path with the alias
            return replacedPath;
        }
        this.aliasedPathCache[path] = null;
        return path;
    }
    registerTranspiler(test, transpilers, prepend = false) {
        const transpilerObject = {
            test,
            transpilers,
        };
        if (prepend) {
            this.loaders.unshift(transpilerObject);
        }
        else {
            this.loaders.push(transpilerObject);
        }
        transpilers.forEach(t => this.addTranspiler(t.transpiler));
        return this.loaders;
    }
    /**
     * Get transpilers from the given query, the query is webpack like:
     * eg. !babel-loader!./test.js
     */
    getLoaders(module, evaluator, query = '') {
        const loader = this.loaders.find(t => t.test(module));
        // Starting !, drop all transpilers
        const transpilers = query.startsWith('!') // eslint-disable-line no-nested-ternary
            ? []
            : loader
                ? loader.transpilers
                : [];
        // Remove "" values
        const transpilerNames = query.split('!').filter(Boolean);
        const extraTranspilers = transpilerNames
            .map(loaderName => loaderName.split('?'))
            .filter(([name]) => !!name)
            .map(([name, options]) => {
            let transpiler = this.getTranspiler(name);
            if (!transpiler) {
                const webpackLoader = new WebpackTranspiler(name, evaluator);
                // If the loader is not installed, we try to run the webpack loader.
                this.addTranspiler(webpackLoader);
                transpiler = webpackLoader;
            }
            const parsedOptions = this.parseOptions(options);
            return { transpiler, options: parsedOptions };
        })
            .reverse(); // Reverse, because webpack is also in reverse order
        const finalTranspilers = [
            ...this.preTranspilers,
            ...transpilers,
            ...extraTranspilers,
            ...this.postTranspilers,
        ];
        return finalTranspilers;
    }
    /**
     * Get the query syntax of the module
     */
    getQuery(module, evaluator, query = '') {
        const loaders = this.getLoaders(module, evaluator, query).reverse();
        return `!${loaders
            .map(t => {
            const configStringified = querystring.encode(t.options);
            const loaderQuery = configStringified ? '?' + configStringified : '';
            return t.transpiler.name + loaderQuery;
        })
            .join('!')}`;
    }
}
